/* eslint-disable @nx/enforce-module-boundaries */
import { Button, HStack, Stack, VStack } from '@mybridge/ui';
import { forwardRef, useContext } from 'react';
// import { SearchFilterCompanyKeywords } from 'v4/components/filters/company-keywords';
import { SearchFilterCompanySize } from 'v4/components/filters/company-size';
import { SearchFilterIndustry } from 'v4/components/filters/industries';
import { SearchFilterLocations } from 'v4/components/filters/locations';
import { SearchContext } from '../../context';
import _ from 'lodash';
import { css } from '@chakra-ui/react';
import { DeleteIcon } from '@mybridge/icons';
export const SearchCompanyPagesFilters = forwardRef(({ ...props }, ref) => {
  const {
    companyPagesFilters,
    setCompanyPagesFilters,
    companyPagesSearchListing,
    resetFilters, setResetFilters 
  } = useContext(SearchContext);
  const { location, industry, company_size, keywords } =
    companyPagesFilters ?? {};

  const onFilterChange = (filter) => {
    if (filter?.location == null) {
      const location = null;
      filter.city = null;
      filter.state = null;
      filter.country = null;
    } else {
      const location = filter?.location?.split(',');
      if (location?.length === 3) {
        filter.city = location?.[0];
        filter.state = location?.[1];
        filter.country = location?.[2];
      } else if (location?.length === 2) {
        filter.state = location?.[0];
        filter.country = location?.[2];
      } else {
        filter.country = location?.[0];
      }
    }
    setCompanyPagesFilters({
      ...(companyPagesFilters ?? {}),
      ...(filter ?? {}),
    });
    // setResetFilters(true)
  };

  const mappingLocation = (item) => {
    if (item.location && item.location !== '') {
      return item.location;
    } else {
      return item?.country !== null
        ? {
            city: item?.city,
            state: item?.state ? item?.state : '',
            country: item?.country,
          }
        : '';
    }
  };

  let fromLocationOptions = _.map(companyPagesSearchListing, mappingLocation);
  const fromIndustryOptions = _.map(companyPagesSearchListing, 'main_industry');
  const cityCounts = {};
  let newFromLocationOptions = []
  fromLocationOptions.forEach((record) => {
    if(record.city){
      cityCounts[record.city] = (cityCounts[record.city] || 0) + 1;
      if (cityCounts[record.city] < 2) {
        newFromLocationOptions.push(record);
      }
    }
  })
  fromLocationOptions = newFromLocationOptions;

  return (
    <>
      <VStack align="stretch" p={3} gap={3} justifyContent="flex-start">
        <SearchFilterLocations
          name="location"
          defaultValue={location}
          onChange={onFilterChange}
          defaultArray={fromLocationOptions}
          filterFor="company"
        />
        <SearchFilterIndustry
          name="industry"
          defaultValue={industry}
          onChange={onFilterChange}
          defaultArray={fromIndustryOptions}
          filterFor="people"
        />
        <SearchFilterCompanySize
          defaultValue={company_size}
          onChange={onFilterChange}
        />
        {/* <SearchFilterCompanyKeywords
          defaultValue={keywords}
          onChange={onFilterChange}
        /> */}
      </VStack>
      {/* {companyPagesFilters && Object.keys(companyPagesFilters ?? {})?.length ? (
        <Button
          variant="secondary"
          justifyContent="flex-start"
          color="#5B5B5B"
          leftIcon={<DeleteIcon />}
          ms="26px"
          onClick={() => setCompanyPagesFilters()}
        >
          Clear Filters
        </Button>
      ) : (
        ''
      )} */}
    </>
  );
});
