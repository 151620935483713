/* eslint-disable @nx/enforce-module-boundaries */
import { Button, HStack, VStack } from '@mybridge/ui';
import _ from 'lodash';
import { forwardRef, useContext } from 'react';
import { SearchFilterIndustry } from 'v4/components/filters/industries';
import { SearchFilterLocations } from 'v4/components/filters/locations';
// import { SearchFilterSortBy } from 'v4/components/filters/sort-by';
import { SearchContext } from '../../context';
import { css } from '@chakra-ui/react';
import { DeleteIcon } from '@mybridge/icons';
export const SearchTeamsFilters = forwardRef(({ ...props }, ref) => {
  const { teamsFilters, setTeamsFilters, teamsSearchListing, resetFilters, setResetFilters  } =
    useContext(SearchContext);
  const { location, industry, sort_by } = teamsFilters ?? {};

  console.log('teamsSearchListing', teamsSearchListing)
  const onFilterChange = (filter) => {
    if (filter?.location == null) {
      const location = null;
      filter.city = null;
      filter.state = null;
      filter.country = null;
    } else {
      const location = filter?.location?.split(',');
      if (location?.length === 3) {
        filter.city = location?.[0];
        filter.state = location?.[1];
        filter.country = location?.[2];
      } else if (location?.length === 2) {
        filter.state = location?.[0];
        filter.country = location?.[2];
      } else {
        filter.country = location?.[0];
      }
    }
    setTeamsFilters({
      ...(teamsFilters ?? {}),
      ...(filter ?? {}),
    });
    // setResetFilters(true)
  };

  const mappingLocation = (item) => {
    if (item.location !== '') {
      return item.location;
    } else {
      return item?.country !== null
        ? { city: item?.city, state: item?.state, country: item?.country }
        : '';
    }
  };
  const fromLocationOptions = _.map(teamsSearchListing, mappingLocation);
  // const fromLocationOptions = _.map(teamsSearchListing, 'location');
  const fromIndustryOptions = _.map(teamsSearchListing, 'industry_name');

  // console.log(location);

  return (
    <>
      <VStack align='stretch' p={3} gap={3} justifyContent="flex-start">
        <SearchFilterLocations
          defaultValue={location}
          onChange={onFilterChange}
          defaultArray={fromLocationOptions}
          name="location"
          filterFor="teams"
        />
        <SearchFilterIndustry
          defaultValue={industry}
          onChange={onFilterChange}
          defaultArray={fromIndustryOptions}
          name="industry"
          filterFor="teams"
        />

        {/* <SearchFilterSortBy defaultValue={sort_by} onChange={onFilterChange} /> */}
        
      </VStack>
      {/* {setTeamsFilters && Object.keys(setTeamsFilters ?? {})?.length ? (
          <Button variant="secondary"
          justifyContent="flex-start"
          color="#5B5B5B"
          leftIcon={<DeleteIcon />}
          ms="26px" onClick={() => setTeamsFilters()}>
            Clear Filters
          </Button>
        ) : (
          ''
        )} */}
    </>
  );
});
