/* eslint-disable @nx/enforce-module-boundaries */
import { HStack, Button, VStack } from '@mybridge/ui';
import { forwardRef, useContext } from 'react';
import { SearchFilterFromCompany } from 'v4/components/filters/company-name';
import { SearchFilterConnections } from 'v4/components/filters/connections';
import { SearchFilterKeywords } from 'v4/components/filters/job-keywords';
import { SearchFilterLocations } from 'v4/components/filters/locations';
import { SearchContext } from '../../context';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { css } from '@chakra-ui/react';
import { DeleteIcon } from '@mybridge/icons';
export const SearchPeopleFilters = forwardRef(({ ...props }, ref) => {
  const { peopleFilters, setPeopleFilters, peopleSearchListing, resetFilters, setResetFilters } =
    useContext(SearchContext);
  const { locations, current_company, connections } = peopleFilters ?? {};
  const { loggedIn } = useSelector((s) => s.user);

  const onFilterChange = (filter) => {
    if (filter?.location == null) {
      const location = null;
      filter.city = null;
      filter.state = null;
      filter.country = null;
    } else {
      const location = filter?.location?.split(',');
      if (location?.length === 3) {
        filter.city = location?.[0];
        filter.state = location?.[1];
        filter.country = location?.[2];
      } else if (location?.length === 2) {
        filter.state = location?.[0];
        filter.country = location?.[2];
      } else {
        filter.country = location?.[0];
      }
    }
    setPeopleFilters({ ...(peopleFilters ?? {}), ...(filter ?? {}) });
    // setResetFilters(true)
  };

  const mappingLocation = (item) => {
    // if (item.location !== '') {
    //   return item.location;
    // } else {
    return item?.country !== null
      ? { city: item?.city, state: item?.state, country: item?.country }
      : '';
    // }
  };

  const fromLocationOptions = _.map(peopleSearchListing, mappingLocation);
  const fromCompanyOptions = _.map(peopleSearchListing, 'current_company');

// console.log(peopleFilters);
  return (
    <>
      <VStack align="stretch" p={3} gap={3} justifyContent="flex-start">
        {loggedIn && (
          <SearchFilterConnections
            defaultValue={connections}
            onChange={onFilterChange}
            name="connections"
          />
        )}
        <SearchFilterLocations
          name="location"
          title="Location"
          defaultValue={locations}
          onChange={onFilterChange}
          defaultArray={fromLocationOptions}
          filterFor="people"
        />
        <SearchFilterFromCompany
          name="current_company"
          title="Current Company"
          defaultValue={current_company}
          onChange={onFilterChange}
          defaultArray={fromCompanyOptions}
          filterFor="people"
        />
        <SearchFilterKeywords onChange={onFilterChange} />
      </VStack>
      {/* {peopleFilters && Object.keys(peopleFilters)?.length ? (
        <Button
          onClick={() => setPeopleFilters()}
          variant="secondary"
          justifyContent="flex-start"
          color="#5B5B5B"
          leftIcon={<DeleteIcon />}
          ms="26px"
        >
          Clear Filters
        </Button>
      ) : (
        ''
      )} */}
    </>
  );
});
